import React, { useState, useEffect } from "react";
import { Button, Card, Container, Form, FormGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../../utils/baseUrl";
import useCommonContext from "../../hooks/useCommonContext";
import AlertComponent from "../AlertComponent";

const Login = () => {
  const { auth, setAuth } = useCommonContext();

  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const history = useNavigate();

  useEffect(() => {
    if (auth?.accessToken) {
      history("/");
    }
  }, [auth, history]);

  const loginUser = async (e) => {
    e.preventDefault();
    if (!userName || !password) {
      setAlertMessage("Vnesite uporabniško ime in geslo!");
      setShowAlert(true);
      return;
    }

    try {
      const res = await fetch(`${BASE_URL}/users/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        withCredentials: true,
        body: JSON.stringify({ userName: userName, password: password }),
      });

      const data = await res.json();

      if (res.status === 500) {
        setAlertMessage("Napaka pri prijavi! " + data.message);
        setShowAlert(true);

        return;
      }

      if (res.status === 201) {
        setAuth(data);
      }

      console.log("Uspešna prijava");
      history("/");
    } catch (error) {
      setAlertMessage(`Neznana napaka! ${error}`);
      setShowAlert(true);
      history("/");
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "500px" }}>
        <Card>
          <Card.Body>
            <Card.Title>
              <h3 className="text-center mb-4">Prijava</h3>{" "}
            </Card.Title>

            <Form onSubmit={loginUser}>
              <FormGroup className="m-2">
                <Form.Label htmlFor="userNameInput">
                  Uporabniško ime:
                </Form.Label>
                <i className="bi bi-person-fill m-1"></i>
                <Form.Control
                  type="text"
                  id="userNameInput"
                  placeholder="Vnesite uporabniško ime"
                  value={userName}
                  onChange={(e) => setuserName(e.target.value)}
                  name="userName"
                  required
                />
              </FormGroup>
              <FormGroup className="m-2">
                <Form.Label htmlFor="passwordInput">Geslo:</Form.Label>
                <i className="bi bi-key m-1"></i>
                <Form.Control
                  type="password"
                  id="passwordInput"
                  placeholder="Vnesite vaše geslo"
                  autoComplete="on"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  name="password"
                  required
                />
              </FormGroup>

              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <Button type="submit" variant="success">
                    <span className="btn-label">
                      <i className="bi bi-plus-circle m-1"></i>
                      Prijava
                    </span>
                  </Button>
                </div>
                <div className="col d-flex align-items-center justify-content-center">
                  <Link className="btn btn-danger" role="button" to="/">
                    <span className="btn-label">
                      <i className="bi bi-x-octagon m-1"></i>
                      Prekliči
                    </span>
                  </Link>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Nimate računa? <Link to="/register">Registracija</Link>
        </div>

        <AlertComponent message={alertMessage} showAlert={showAlert} />
      </div>
    </Container>
  );
};

export default Login;
