import React, { useState } from "react";
import { Button, Card, Container, Form, FormGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import convertToBase64 from "../../utils/convertImgToBase64";

const AddSlideshowImage = () => {
  const history = useNavigate();
  const api = useFetch();

  const [slideshowImage, setSlideshowImage] = useState({});

  const handleFileUpload = async (files) => {
    const file = files[0];
    const base64 = await convertToBase64(file);

    console.log(`base64 ${base64.length}`);
    setSlideshowImage(base64);
  };

  const uploadSlideshowImage = async (e) => {
    e.preventDefault();

    try {
      const { res, data } = await api(
        "/slideshowImage",
        new AbortController().signal,
        "POST",
        {
          slideshowImage: slideshowImage,
        }
      );

      if (res.status === 201 && data.savedSlideshowImageSuccessfully === true) {
        console.log("Uspešno shranili sliko projekcije v bazo!");
        history("/");
      }
    } catch (error) {
      alert(`Napaka pri slike projekcije! ${error.message}`);
    }
  };

  return (
    <div>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div className="w-100" style={{ maxWidth: "1000px" }}>
          <Card>
            <Card.Body>
              <Card.Title>
                <h3 className="text-center mb-4">
                  Dodajanje slike za projekcijo
                </h3>{" "}
              </Card.Title>

              <Form onSubmit={uploadSlideshowImage}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="imageInput">
                    Nalaganje slike diaprojekcije (naj bodo 1920x okoli 400-600):
                  </Form.Label>
                  <i className="bi bi-images m-1"></i>

                  <Form.Control
                    id="imageInput"
                    type="file"
                    required
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleFileUpload(e.target.files)}
                  />
                </Form.Group>

                <FormGroup>
                  <div className="row">
                    <div className="col d-flex align-items-center justify-content-center">
                      <Button type="submit" variant="success">
                        <span className="btn-label">
                          <i className="bi bi-plus-circle m-1"></i>
                          Dodaj sliko
                        </span>
                      </Button>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                      <Link className="btn btn-danger" role="button" to="/">
                        <span className="btn-label">
                          <i className="bi bi-x-octagon m-1"></i>
                          Prekliči
                        </span>
                      </Link>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default AddSlideshowImage;
