import BASE_URL from "../utils/baseUrl";

const useFetch = () => {
  const sendRequest = async (url, config) => {
    try {
      const res = await fetch(BASE_URL + url, config);
      const data = await res.json();

      if (res.status === 401) {
        if (data?.showAlert) alert(`Niste poslali žetona! ${data.message}`);

        return { res, undefined };
      }

      if (res.status === 500) {
        if (data?.showAlert)
          alert(`Neznana napaka na strežniku! ${data.message}`);
        return { res, undefined };
      }

      if (res.status === 403) {
        if (data?.showAlert) alert(`Neveljaven žeton! ${data.message}`);
        return { res, undefined };
      }

      return { res, data };
    } catch (error) {
      console.log(`ime napake: ${error.name} ${error.message}`);
      if (error.name !== "AbortError") {
        alert("Napaka pri pošiljanju zahteve!");
      }

      return {};
    }
  };

  const fetcher = async (url, signal, method = "GET", requestData = {}) => {
    let config = {
      credentials: "include", //nujno da poleg pošljemo httpOnly piškotke
      method: method, //določimo metodo
      headers: {},
    };
    config["headers"] = {
      "Content-Type": "application/json",
    };

    if (method !== "GET") {
      config["body"] = JSON.stringify(requestData);
    }

    config["signal"] = signal;
    const { res, data } = await sendRequest(url, config);
    return { res, data };
  };

  return fetcher;
};

console.log(`link: ${process.env.REACT_APP_LINK}`);
export default useFetch;
