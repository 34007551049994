import React, { useState } from "react";
import { Button, Card, Container, Form, FormGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../../utils/baseUrl";

const Register = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAdmin, setPasswordAdmin] = useState("");

  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [passwordAgainError, setPasswordAgainError] = useState("");
  const [passwordErrorClassName, setPasswordErrorClassName] =
    useState("text-danger");
  const [passwordAgainErrorClassName, setPasswordAgainErrorClassName] =
    useState("text-danger");

  const history = useNavigate();

  const registerUser = (e) => {
    e.preventDefault();
    if (!userName || !password || !passwordAgain) {
      alert("Vnesiti uporabniško ime in dvakrat geslo!");
      return;
    }

    if (password !== passwordAgain) {
      alert("Vnesite enaki gesli!");
      return;
    }

    fetch(`${BASE_URL}/users/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userName: userName, password: password, passwordAdmin: passwordAdmin }),
    })
      .then(async (res) => {
        const msg = await res.json();

        if (res.status === 500) {
          alert("Napaka pri prijavi! " + msg.message);
          return;
        }

        history("/");
      })
      .catch(() => {
        alert("Neznana napaka pri prijavi!");
        history("/");
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    setPasswordError("");
    setPasswordAgainError("");

    if (password.length > 3 && password) {
      setPasswordError("Ustrezno geslo!");
      setPasswordErrorClassName("text-success");
    } else {
      setPasswordError("Neustrezno geslo!");
      setPasswordErrorClassName("text-danger");
    }
  };

  const handlePasswordAgainChange = (e) => {
    setPasswordAgain(e.target.value);

    if (password === e.target.value) {
      setPasswordAgainError("Gesli se ujemata");
      setPasswordAgainErrorClassName("text-success");
    } else {
      setPasswordAgainError("Gesli se ne ujemata!");
      setPasswordAgainErrorClassName("text-danger");
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "800px" }}>
        <Card>
          <Card.Body>
            <Card.Title>
              <h3 className="text-center mb-4">Registracija</h3>{" "}
            </Card.Title>

            <Form onSubmit={registerUser}>
              <FormGroup className="m-2">
                <Form.Label htmlFor="userNameInput">
                  Uporabniško ime:
                </Form.Label>
                <i className="bi bi-person-fill m-1"></i>
                <Form.Control
                  type="text"
                  id="userNameInput"
                  placeholder="Vnesite uporabniško ime"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  name="userName"
                  required
                />
              </FormGroup>
              <FormGroup className="m-2">
                <Form.Label htmlFor="passwordInput">Geslo:</Form.Label>
                <i className="bi bi-key m-1"></i>
                <Form.Control
                  type="password"
                  id="passwordInput"
                  autoComplete="on"
                  placeholder="Vnesite želeno geslo"
                  value={password}
                  onChange={handlePasswordChange}
                  name="password"
                  required
                />
                <div id="passwordHelpBlock" className="form-text m-1">
                  Geslo naj ima vsaj 5 znakov.
                </div>

                <div className={passwordErrorClassName}>{passwordError}</div>
              </FormGroup>
              <FormGroup className="m-2">
                <div className="mb-3 align-self-center">
                  <Form.Label htmlFor="passwordAgainInput">
                    Še enkrat geslo:
                  </Form.Label>
                  <i className="bi bi-key-fill m-1"></i>
                  <Form.Control
                    type="password"
                    autoComplete="off"
                    id="passwordAgainInput"
                    placeholder="Ponovno vnesite vaše geslo"
                    value={passwordAgain}
                    onChange={handlePasswordAgainChange}
                    name="passwordAgain"
                    required
                  />
                </div>

                <div className={passwordAgainErrorClassName}>
                  {passwordAgainError}
                </div>
              </FormGroup>

              <FormGroup className="m-2">
                <div className="mb-3 align-self-center">
                  <Form.Label htmlFor="pwdAdmin">
                    Geslo za administratorje:
                  </Form.Label>
                  <i className="bi bi-key-fill m-1"></i>
                  <Form.Control
                    type="password"
                    id="pwdAdmin"
                    autoComplete="off"
                    placeholder="Vnos administratorskega gesla"
                    value={passwordAdmin}
                    name="passwordAdmin"
                    onChange={(e) => setPasswordAdmin(e.target.value)}
                    required
                  />
                  <Form.Text id="passwordHelpBlock" muted>
                    Če želite narediti račun za dodajanje novic morate poznati
                    administratorsko geslo. To geslo se razlikuje od gesla, ki
                    ste ga izbrali za vaš račun.
                  </Form.Text>
                </div>
              </FormGroup>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <Button type="submit" variant="success">
                    <span className="btn-label">
                      <i className="bi bi-plus-circle m-1"></i>
                      Registraj se
                    </span>
                  </Button>
                </div>
                <div className="col d-flex align-items-center justify-content-center">
                  <Link className="btn btn-danger" role="button" to="/">
                    <span className="btn-label">
                      <i className="bi bi-x-octagon m-1"></i>
                      Prekliči
                    </span>
                  </Link>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>

        <div className="w-100 text-center mt-2">
          Že imate račun? <Link to="/login">Prijava</Link>
        </div>
      </div>
    </Container>
  );
};

export default Register;
