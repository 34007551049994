import React, { useEffect, useState } from "react";
import BASE_URL from "../../utils/baseUrl";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import useCommonContext from "../../hooks/useCommonContext";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

const ShowPlayers = () => {
  const [players, setPlayers] = useState([]);
  const api = useFetch();
  const { auth } = useCommonContext();

  const fetchPlayers = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/player/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });

      setPlayers(await response.json());
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri igralskih pozicij");
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchPlayers(signal).catch(console.error);

    return () => {
      controller.abort();
    };
  }, []);

  const deletePlayer = async (playerId) => {
    try {
      const { data, res } = await api(
        `/player/${playerId}`,
        new AbortController().signal,
        "DELETE"
      );

      if (res.status === 401) {
        alert("Za izbris igralca morate biti prijavljeni!");
        return;
      }

      if (res.status === 200 && data.playerDeletedSuccessfully) {
        console.log("igralec uspešno izbrisan");
        await fetchPlayers(new AbortController().signal);
      }
    } catch (error) {
      alert(`Napaka pri izbrisu igralca! ${error?.message}`);
    }
  };

  return (
    <div>
      <h1 className="text-center">Igralci Špotnega društva Bukovci</h1>

      <Container
        className="d-flex align-items-center justify-content-center g-3"
        style={{ minHeight: "100vh" }}
      >
        <Row className="g-3">
          {players &&
            players.map((player) => (
              <Col className="col-12 col-md-6 col-lg-4" key={Math.random()}>
                <Card className="h-100">
                  <Card.Img variant="top" src={player.image} />

                  <Card.Body>
                    <Card.Title>
                      <b>{player.name} {player.surname}</b> <i>{player.positionSloName}</i>
                    </Card.Title>
            
                  </Card.Body>
               
                  {auth?.accessToken ? (
                    <Card.Footer className="text-muted text-center">
                      <Button
                        onClick={(e) => deletePlayer(player._id)}
                        variant="danger"
                      >
                        Izbriši igralca
                      </Button>{" "}
                      <Link to={`/urediIgralca/${player._id}`}>
                        <Button type="button" variant="success">
                          Uredi igralca
                        </Button>
                      </Link>
                    </Card.Footer>
                  ) : (
                    <></>
                  )}
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default ShowPlayers;
