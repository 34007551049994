import React from 'react'
import { Alert } from 'react-bootstrap';


const AlertComponent = ({ message, showAlert }) =>
{
    return (
        <>
            <Alert variant="warning" show={showAlert} dismissible>
                <Alert.Heading>Napaka!</Alert.Heading>
                <p>
                    {message}
                </p>
            </Alert>
        </>
    )
}

export default AlertComponent

