import React, { useState, useEffect } from "react";
import BASE_URL from "../../utils/baseUrl";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import useCommonContext from "../../hooks/useCommonContext";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);
  const api = useFetch();
  const { auth } = useCommonContext();

  const fetchSponsors = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/sponsor/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });

      setSponsors(await response.json());
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri pridobivanju spozorjev");
      }
    }
  };
  const deletePlayer = async (playerId) => {
    try {
      const { data, res } = await api(
        `/sponsor/${playerId}`,
        new AbortController().signal,
        "DELETE"
      );

      if (res.status === 401) {
        alert("Za izbris sponzorja morate biti prijavljeni!");
        return;
      }

      if (res.status === 200 && data.sponsorDeletedSuccessfully) {
        console.log("sponzor uspešno izbrisan");
        await fetchSponsors(new AbortController().signal);
      }
    } catch (error) {
      alert(`Napaka pri izbrisu spozorja! ${error?.message}`);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchSponsors(signal).catch(console.error);

    return () => {
      controller.abort();
    };
  }, []);
  return (
    <div>
      <h1 className="text-center">Sponzorji Špotnega društva Bukovci</h1>

      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Row className="g-3">
          {sponsors &&
            sponsors.map((sponsor) => (
              <Col className="col-12 col-md-6 col-lg-4" key={Math.random()}>
                <Card className="h-100">
                  <Card.Img variant="top" src={sponsor.sponsorImage} />
                  <Card.Body>
                    <Card.Title>{sponsor.sponsorName}</Card.Title>
                  </Card.Body>
                  {auth?.accessToken ? (
                    <Card.Footer className="text-muted text-center">
                      <Button
                        onClick={(e) => deletePlayer(sponsor._id)}
                        variant="danger"
                      >
                        Izbriši Sponzorja
                      </Button>{" "}
                      <Link to={`/urediSponzorja/${sponsor._id}`}>
                        <Button type="button" variant="success">
                          Uredi Sponzorja
                        </Button>
                      </Link>
                    </Card.Footer>
                  ) : (
                    <></>
                  )}
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default Sponsors;
