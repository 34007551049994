import React, { useState, useEffect } from "react";
import BASE_URL from "../../utils/baseUrl";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import useCommonContext from "../../hooks/useCommonContext";
import useFetch from "../../hooks/useFetch";

const SlideshowImages = () => {
  const [slideshowImages, setSlideshowImages] = useState([]);
  const api = useFetch();
  const { auth } = useCommonContext();

  const fetchSlideshowImages = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/slideshowImage/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });

      setSlideshowImages(await response.json());
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri pridobivanju spozorjev");
      }
    }
  };
  const deletePlayer = async (playerId) => {
    try {
      const { data, res } = await api(
        `/slideshowImage/${playerId}`,
        new AbortController().signal,
        "DELETE"
      );

      if (res.status === 401) {
        alert("Za izbris slike projekcije morate biti prijavljeni!");
        return;
      }

      if (res.status === 200 && data.slideshowImageDeletedSuccessfully) {
        console.log("slika uspešno izbrisan");
        await fetchSlideshowImages(new AbortController().signal);
      }
    } catch (error) {
      alert(`Napaka pri izbrisu slike projekcije! ${error?.message}`);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchSlideshowImages(signal).catch(console.error);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div>
      <h1 className="text-center">Pregled slik v projekciji</h1>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Row className="g-3">
          {slideshowImages &&
            slideshowImages.map((slideshowImage) => (
              <Col className="col-12 col-md-6 col-lg-4" key={Math.random()}>
                <Card className="h-100">
                  <Card.Img variant="top" src={slideshowImage.slideshowImage} />

                  {auth?.accessToken ? (
                    <Card.Footer className="text-muted text-center">
                      <Button
                        onClick={(e) => deletePlayer(slideshowImage._id)}
                        variant="danger"
                      >
                        Izbriši sliko projekcije
                      </Button>
                    </Card.Footer>
                  ) : (
                    <></>
                  )}
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default SlideshowImages;
