import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
const Footer = () => {
  return (
    <MDBFooter
      bgColor="yellow"
      className="text-center text-muted"
      style={{ maxHeight: "170px" }}
    >
      <MDBContainer className="p-2">
        <MDBRow>
          <MDBCol>
            <h6>Spremljajte nas na naših družbenih omrežjih:</h6>

            <ul className="list-unstyled mb-0 ">
              <li>
                <a
                  href="https://www.youtube.com/channel/UC3lqVxg7hXAMBUHD448RRyg"
                  className="me-2 text-reset"
                >
                  <i className="bi bi-youtube text-decoration-none"> YouTube</i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100010411505209"
                  className="me-2 text-reset"
                >
                  <i className="bi bi-facebook "> Facebook</i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/markoton.si/"
                  className="me-2 text-reset"
                >
                  <i className="bi bi-facebook "> Facebook Markoton</i>
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol>
            <img src="/mnz_ptuj_logo.png"  width="100" height="100"  alt="logo"/>
          </MDBCol>
          <MDBCol>
            <h6 className="text-uppercase mb-0">Kontakt</h6>

            <ul className="list-unstyled">
              <li>
                <i className="bi bi-geo-alt"></i> Bukovci 98/e, 2281 Markovci
              </li>
              <li>
                <i className="bi bi-envelope"></i>
                info@sdbukovci.com
              </li>
              <li>
                <i className="bi bi-telephone"></i> +0038631-659-765 Janez
                Janžekovič, predsednik
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "#0000FE" }}
      >
        Vse pravice pridržane: 1977-{new Date().getFullYear()} {" "}
        <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
          Športno društvo Bukovci
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
