import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle'; //celotna boostrap js knjižnica
import 'bootstrap/dist/css/bootstrap.css'; //podpora za bootstrap
import 'bootstrap-icons/font/bootstrap-icons.css';
import { CommonProvider } from './contexts/CommonProvider';
import  {disableReactDevTools} from "@fvilers/disable-react-devtools"

console.log(`okolje: ${process.env.NODE_ENV}`)
if (process.env.NODE_ENV === 'production') disableReactDevTools()

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <CommonProvider>
      <App />
    </CommonProvider>
  </React.StrictMode>
);