import React, { useState, useEffect } from "react";
import { Button, Card, Form, FormGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import convertToBase64 from "../../utils/convertImgToBase64";
import BASE_URL from "../../utils/baseUrl";

const AddPlayer = () => {
  const history = useNavigate();
  const api = useFetch();

  const [playerName, setPlayerName] = useState("");
  const [playerSurname, setPlayerSurname] = useState("");

  const [playerPositions, setPlayerPositions] = useState([]);
  const [selectedPlayerPositionId, setSelectedPlayerPositionId] = useState("");

  const [playerImage, setPlayerImage] = useState({});

  const fetchPlayerPositions = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/positions/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });
      setPlayerPositions(await response.json());
      console.log("first");
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri igralskih pozicij");
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchPlayerPositions(signal)
      // make sure to catch any error
      .catch(console.error);

    return () => {
      controller.abort();
    };
  }, []);

  const handleFileUpload = async (files) => {
    const file = files[0];
    const base64 = await convertToBase64(file);

    console.log(`base64 ${base64.length}`);
    setPlayerImage(base64);
  };

  const uploadPlayer = async (e) => {
    e.preventDefault();
    if (playerName === "" || playerSurname === "") {
      alert("Niste podali imena ali priimka igralca");
      return;
    }

    if (selectedPlayerPositionId === "") {
      setSelectedPlayerPositionId(playerPositions[0]._id);
      console.log(`izbrana pozicija je prazna!`);
    }

    try {
      const { res, data } = await api(
        "/player",
        new AbortController().signal,
        "POST",
        {
          name: playerName,
          surname: playerSurname,
          image: playerImage,
          position:
            selectedPlayerPositionId === ""
              ? playerPositions[0]._id
              : selectedPlayerPositionId,
        }
      );

      if (res.status === 201 && data.playerCreatedSuccessfully === true) {
        console.log("Uspešno shranili igralca v bazo!");
        history("/");
      }
    } catch (error) {
      alert(`Napaka pri dodajaju igralca! ${error.message}`);
    }
  };
  return (
    <div>
      {/* <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw"}}
      >   */}
      <div
        className="mt-5"
        style={{ minHeight: "100vh", minWidth: "100vw"}}

      >
        <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
          <Card>
            <Card.Body>
              <Card.Title>
                <h3 className="text-center mb-4">Dodajanje igralca</h3>{" "}
              </Card.Title>

              <Form onSubmit={uploadPlayer}>
                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerName">Ime igralca:</Form.Label>
                  <i className="bi bi-newspaper m-1"></i>
                  <Form.Control
                    type="text"
                    id="playerName"
                    placeholder="Vnesite ime igralca"
                    aria-describedby="newsTitle"
                    defaultValue={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    name="name"
                    required
                  />
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerSurname">
                    Priimek igralca:
                  </Form.Label>
                  <i className="bi bi-newspaper m-1"></i>
                  <Form.Control
                    type="text"
                    id="playerSurname"
                    placeholder="Vnesite priimek igralca"
                    aria-describedby="newsTitle"
                    defaultValue={playerSurname}
                    onChange={(e) => setPlayerSurname(e.target.value)}
                    name="name"
                    required
                  />
                </FormGroup>
                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerPosition">
                    Pozicija igralca:
                  </Form.Label>
                  <Form.Select
                    aria-label="Pozicija igralca"
                    onChange={(e) => {
                      setSelectedPlayerPositionId(e.target.value);
                    }}
                  >
                    {playerPositions.map((position) => (
                      <option key={Math.random()} value={position._id} selected={position._id === selectedPlayerPositionId}>
                        {position.positionName}
                      </option>
                    ))}
                  </Form.Select>
                </FormGroup>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="imageInput">
                    Nalaganje slike igralca:
                  </Form.Label>
                  <i className="bi bi-images m-1"></i>

                  <Form.Control
                    id="imageInput"
                    type="file"
                    required
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleFileUpload(e.target.files)}
                  />
                </Form.Group>

                <FormGroup>
                  <div className="row">
                    <div className="col d-flex align-items-center justify-content-center">
                      <Button type="submit" variant="success">
                        <span className="btn-label">
                          <i className="bi bi-plus-circle m-1"></i>
                          Dodaj igralca
                        </span>
                      </Button>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                      <Link className="btn btn-danger" role="button" to="/">
                        <span className="btn-label">
                          <i className="bi bi-x-octagon m-1"></i>
                          Prekliči
                        </span>
                      </Link>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AddPlayer;
