import React from "react";
import { Container, Carousel, Row, Card, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import BASE_URL from "../utils/baseUrl";
import ReactPaginate from "react-paginate";
import useCommonContext from "../hooks/useCommonContext";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";

const Home = () => {
  const { auth } = useCommonContext();
  const api = useFetch();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [news, setNews] = useState([]);
  const [slideshowImages, setSlideshowImages] = useState([]);

  const [newsPerPage] = useState(5);
  const [newsOffset, setItemOffset] = useState(0);

  const endOffset = newsOffset + newsPerPage;
  let currentNews = news.slice(newsOffset, endOffset);
  const pageCount = Math.ceil(news.length / newsPerPage);

  const deleteNews = async (newsId) => {
    console.log(`id novice za izbris: ${newsId}`);

    try {
      const { data, res } = await api(
        `/news/${newsId}`,
        new AbortController().signal,
        "DELETE"
      );
      if (res.status === 401) {
        alert("Za izbris novice morate biti prijavljeni!");
        return;
      }

      if (res.status === 200 && data.newsDeletedSuccessfully) {
        console.log("novica uspešno izbriasna");
        await fetchNews(new AbortController().signal);
      }
    } catch (error) {
      alert(`Napaka pri izbrisu novice! ${error?.message}`);
    }
  };

  const fetchNews = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/news/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });
      setNews(await response.json());
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri pridovivanju novic");
      }
    }
  };

  const fetchSlideshowImages = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/slideshowImage/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });

      setSlideshowImages(await response.json());
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri pridovivanju slik");
      }
    }
  };

  useEffect(() => {
    const controllerNews = new AbortController();
    const signalNews = controllerNews.signal;

    const controllerSildeshow = new AbortController();
    const signalSildeshow = controllerSildeshow.signal;

    fetchNews(signalNews)
      // make sure to catch any error
      .catch(console.error);

    fetchSlideshowImages(signalSildeshow).catch(console.error);

    return () => {
      controllerNews.abort();
      controllerSildeshow.abort();
      console.log("cleanup Home!");
    };
  }, []);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * newsPerPage) % news.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };


  return (
    <>
      <Row>
        <Carousel>
          {slideshowImages &&
            slideshowImages.map((slideshowImage) => (
              <Carousel.Item key={Math.random()}>
                <img
                  className="d-block w-100"
                  src={slideshowImage.slideshowImage}
                  alt="Slika projekcije"
                />
              </Carousel.Item>
            ))}
        </Carousel>
      </Row>
    
      <Row>
        <Container
          className="d-flex align-items-center justify-content-center g-3"
          style={{ minHeight: "100vh" }}
        >
          <div
            className="row d-flex align-items-center  justify-content-evenly"
            style={{ width: "95%" }}
          >
            {currentNews &&
              currentNews.map((oneNews) => (
                <Card style={{ margin: "2px" }} key={Math.random()}>
                  <Card.Header className="mb-2 text-muted">
                    Posodobljeno:{" "}
                    {new Date(oneNews.updatedAt).toLocaleTimeString(
                      "sl-SI",
                      options
                    )}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{oneNews.newsTitle}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Ustvarjeno:{" "}
                      {new Date(oneNews.createdAt).toLocaleTimeString(
                        "sl-SI",
                        options
                      )}
                    </Card.Subtitle>
                    <Card.Text>{oneNews.newsText}</Card.Text>
                  </Card.Body>

                  {oneNews.newsImages.length > 0 ? (
                    <Carousel>
                      {oneNews.newsImages.map((image) => (
                        <Carousel.Item key={Math.random()}>
                          <img
                            style={{
                              maxWidth: "400px",
                              maxHeight: "400px",
                              margin: "10px",
                            }}
                            className="img-fluid rounded mx-auto d-block"
                            src={image}
                            alt="Slika"
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <>Ni fotografij</>
                  )}

                  {auth?.accessToken ? (
                    <Card.Footer className="text-muted text-center">
                      <Button
                        onClick={(e) => deleteNews(oneNews._id)}
                        variant="danger"
                      >
                        Izbriši novico
                      </Button>{" "}
                      <Link to={`/urediNovico/${oneNews._id}`}>
                        <Button type="button" variant="success">
                          Uredi novico
                        </Button>
                      </Link>
                    </Card.Footer>
                  ) : (
                    <></>
                  )}
                </Card>
              ))}

            <ReactPaginate
              onPageChange={handlePageClick}
              pageCount={pageCount}
              previousLabel={"< Nazaj"}
              nextLabel={"Naprej >"}
              containerClassName={"pagination"}
              pageLinkClassName={"page-number"}
              previousLinkClassName={"page-number"}
              nextLinkClassName={"page-number"}
              activeLinkClassName={"active"}
              renderOnZeroPageCount={null}
            />
          </div>
        </Container>
      </Row>
    </>
  );
};

export default Home;
