import React from "react";
import { Link } from "react-router-dom";
import useCommonContext from "../../hooks/useCommonContext";
import LogedInNavbar from "./LogedInNavbar";

const Header = () => {
  const { auth } = useCommonContext();

  return (
    <nav className="navbar  sticky-top navbar-expand-lg navbar-light bg-light" >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/sd_bukovci.jpg" alt="logo" width="50" height="50" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {auth?.accessToken ? (
          <LogedInNavbar />
        ) : (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Domača stran
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/pregledIgralcev"
                >
                  Vsi igralci
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/sponzorji"
                >
                  Sponzorji
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link active" to="/register">
                  Registracija
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link active" to="/login">
                  Prijava
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
