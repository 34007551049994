import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/loginAndRegistration/Login";
import EditNews from "./components/EditNews";
import Register from "./components/loginAndRegistration/Register";
import RequireAuth from "./components/RequireAuth";
import useFetch from "./hooks/useFetch";
import { useEffect } from "react";
import useRefreshToken from "./hooks/useRefreshToken";
import useCommonContext from "./hooks/useCommonContext";
import Footer from "./components/Footer";
import AddNews from "./components/AddNews";
import AddPlayer from "./components/players/AddPlayer";
import ShowPlayers from "./components/players/ShowPlayers";
import EditPlayer from "./components/players/EditPlayer";
import "./App.css";
import Sponsors from "./components/sponsors/Sponsors";
import AddSponsor from "./components/sponsors/AddSponsor";
import EditSponsor from "./components/sponsors/EditSponsor";
import Header from "./components/navbar/Header";
import AddSlideshowImage from "./components/bannerEditing/AddSlideshowImage";
import SlideshowImages from "./components/bannerEditing/SlideshowImages";

const App = () => {
  const api = useFetch();
  const { auth, setAuth } = useCommonContext();
  const refreshToken = useRefreshToken();

  useEffect(() => {
    const tokenRefreshInterval = setInterval(async () => {
      if (auth?.accessToken) {
        //če je seja lahko izvedemo podaljšanje
        await refreshToken();
        console.log("Posodobitev žetona!");
      }
    }, 100000);

    const renewSession = () => {
      window.onload = async (e) => {
        const { res, data } = await api(
          "/tokens/isSessionValid",
          new AbortController().signal,
          "GET"
        );
        if (res?.status === 201 && data !== undefined) {
          setAuth(data);
        }
      };
    };

    renewSession();

    return () => clearInterval(tokenRefreshInterval);
  }, [api, auth, setAuth, refreshToken]);

  return (
    <BrowserRouter>
      <Header />
      <Container fluid>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* domača stran */}

          <Route path="/register" element={<Register />} />
          {/* stran za registracijo */}

          <Route path="/login" element={<Login />} />
          <Route path="/pregledIgralcev" element={<ShowPlayers />} />
          <Route path="/sponzorji" element={<Sponsors />} />
          {/* stran za prijavo */}

          {/* Zavarovanje poti */}
          <Route element={<RequireAuth />}>
            <Route path="/urediNovico/:newsId" element={<EditNews />} />
            <Route path="/urediIgralca/:playerId" element={<EditPlayer />} />
            <Route
              path="/urediSponzorja/:sponsorId"
              element={<EditSponsor />}
            />

            <Route path="/addNews" element={<AddNews />} />
            <Route path="/addPlayer" element={<AddPlayer />} />
            <Route path="/addSponsor" element={<AddSponsor />} />
            <Route path="/addSlideshowImage" element={<AddSlideshowImage />} />
            <Route path="/viewSlideshowImage" element={<SlideshowImages />} />
          </Route>
        </Routes>
      </Container>
      <Footer />
    </BrowserRouter>
    // <a href="https://www.flaticon.com/free-icons/soccer" title="soccer icons">Soccer icons created by Freepik - Flaticon</a>
  );
};

export default App;
