import React, { useState } from "react";
import { Button, Card, Container, Form, FormGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import convertToBase64 from "../utils/convertImgToBase64";

const AddNews = () => {
  const [newsTitle, setNewsTitle] = useState("");
  const [newsText, setNewsText] = useState("");

  const [newsImages, setNewsImages] = useState([]);
  const history = useNavigate();
  const api = useFetch();
  

  const uploadNews = async (e) => {
    e.preventDefault();
    if (newsText === "" || newsTitle === "") {
      alert("Niste podali naslova in vsebine novice!");
      return;
    }

    console.log(
      `naslov: ${newsTitle} vsebina: ${newsText} slike: ${newsImages.length}`
    );

    try {
      const { res, data } = await api(
        "/news",
        new AbortController().signal,
        "POST",
        {
          newsTitle: newsTitle,
          newsText: newsText,
          newsImages: newsImages,
        }
      );

      if (res.status === 500) {
        alert("Neznana strežniška napaka pri dodajanju novice!");
        return;
      }

      if (res.status === 201 && data.newsCreatedSuccessfully === true) {
        console.log("Uspešno shranili novico v bazo!");
        history("/");
      }
    } catch (error) {
      alert(`Napaka pri dodajaju novice! ${error.message}`);
    }
  };

  const handleFileUpload = async (files) => {
    for (const file of files) {
      const base64 = await convertToBase64(file);

      console.log(`base64 ${base64.length}`);
      setNewsImages((newsImages) => [...newsImages, base64]);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100" style={{ maxWidth: "1000px" }}>
        <Card>
          <Card.Body>
            <Card.Title>
              <h3 className="text-center mb-4">Dodajanje novice</h3>{" "}
            </Card.Title>

            <Form onSubmit={uploadNews}>
              <FormGroup className="m-2">
                <Form.Label htmlFor="userNameInput">Naslov novice:</Form.Label>
                <i className="bi bi-newspaper m-1"></i>
                <Form.Control
                  type="text"
                  id="newsTitleInput"
                  placeholder="Vnesite naslov novice"
                  aria-describedby="newsTitle"
                  defaultValue={newsTitle}
                  onChange={(e) => setNewsTitle(e.target.value)}
                  name="newsTitle"
                  required
                />
              </FormGroup>

              <FormGroup className="m-2">
                <Form.Label htmlFor="userNameInput">
                  Besedilo novice:
                </Form.Label>
                <i className="bi bi-body-text m-1"></i>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="NK Bukovci so ponovno zmagali..."
                    id="floatingTextarea2"
                    aria-describedby="newsText"
                    value={newsText}
                    onChange={(e) => setNewsText(e.target.value)}
                    style={{ height: "50vh", maxHeight: "150px" }}
                  ></textarea>
                  <label htmlFor="floatingTextarea2">
                    NK Bukovci so ponovno zmagali...
                  </label>
                </div>
              </FormGroup>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="imageInput">Nalaganje slik:</Form.Label>
                <i className="bi bi-images m-1"></i>

                <Form.Control
                  id="imageInput"
                  type="file"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleFileUpload(e.target.files)}
                />
              </Form.Group>

              <FormGroup>
                <div className="row">
                  <div className="col d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Dodaj novico
                      </span>
                    </Button>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center">
                    <Link className="btn btn-danger" role="button" to="/">
                      <span className="btn-label">
                        <i className="bi bi-x-octagon m-1"></i>
                        Prekliči
                      </span>
                    </Link>
                  </div>
                </div>
              </FormGroup>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default AddNews;
