import useCommonContext from "../hooks/useCommonContext";
import BASE_URL from "../utils/baseUrl";

const useRefreshToken = () =>
{
    const { setAuth } = useCommonContext();

    const refreshToken = async () =>
    {
        const response = await fetch(BASE_URL + '/tokens', {
            method: 'GET',
            credentials: 'include',
        }).catch(
            err =>
            {
                alert(`Neznana napaka pri posodabljanju žetona ${err}`);
                return
            })

        if (response.status === 403)
        {
            alert("Napaka pri obnovitvi žetona!")
            return
        }
        else if (response.status === 401)
        {
            alert("Ni žetona!")
            return
        } 
        else if (response.status === 500)
        {
            alert("Neznana napaka na strežniku! pri obnovitvi žetena!")
            return
        }


        const tempData = await response.json()
        setAuth(tempData)
    }

    return refreshToken
}

export default useRefreshToken;