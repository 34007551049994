import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  FormGroup,
  ListGroup,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import convertToBase64 from "../../utils/convertImgToBase64";
import BASE_URL from "../../utils/baseUrl";

const EditSponsor = () => {
  const history = useNavigate();
  const api = useFetch();

  const [sponsorName, setSponsorName] = useState("");

  const [sponsorImage, setSponsorImage] = useState("");
  const routeParams = useParams();

  const fetchSponsorById = useCallback(
    async (signal) => {
      try {
        const response = await fetch(
          `${BASE_URL}/sponsor/${routeParams.sponsorId}`,
          {
            method: "GET",
            signal: signal,
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 404) {
          alert(`Ni sponzorja z ID-jem ${routeParams.sponsorId}`);
          return;
        }

        if (response.status === 500) {
          alert(
            `Napaka pri pridobivanju sponzorja z ID: ${routeParams.sponsorId}`
          );
          return;
        }

        const sponsorData = await response.json();
        setSponsorName(sponsorData.sponsorName);

        console.log("nastavitev stanja");
        setSponsorImage(sponsorData.sponsorImage);
      } catch (error) {}
    },
    [routeParams.sponsorId]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchSponsorById(signal).catch(console.error);

    return () => {
      controller.abort();
    };
  }, [fetchSponsorById]);

  const handleFileUpload = async (files) => {
    const file = files[0];
    const base64 = await convertToBase64(file);

    console.log(`base64 ${base64.length}`);
    setSponsorImage(base64);
  };

  const updateSponsor = async (e) => {
    e.preventDefault();
    if (sponsorName === "") {
      alert("Niste podali imena sponzorja");
      return;
    }

    try {
      const { res, data } = await api(
        `/sponsor/${routeParams.sponsorId}`,
        new AbortController().signal,
        "PUT",
        {
          sponsorName: sponsorName,
          sponsorImage: sponsorImage,
        }
      );

      if (res.status === 404) {
        alert("Ni podan id spozorja za posodobitev!");
      }

      if (res.status === 200 && data.sponsorUpdatedSuccessfully === true) {
        console.log("Uspešno posodobili sponzorja v bazi!");
        history("/sponzorji");
      }
    } catch (error) {
      alert(`Napaka pri posodobitvi sponzorja! ${error.message}`);
    }
  };

  const handleImageDeletion = async () => {
    setSponsorImage("");

    try {
      const { res, data } = await api(
        `/sponsor/${routeParams.playerId}`,
        new AbortController().signal,
        "PUT",
        {
          image: "",
          deleteImage: true,
        }
      );

      if (res.status === 404) {
        alert("Ni podan id sponzorja za posodobitev!");
      }

      if (res.status === 200 && data.sponsorUpdatedSuccessfully === true) {
        console.log("Uspešno posodobili sponzorja v bazi!");
      }
    } catch (error) {
      alert(`Napaka pri posodobitvi sponzorja! ${error.message}`);
    }
  };

  return (
    <div>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div className="w-100" style={{ maxWidth: "1000px" }}>
          <Card>
            <Card.Body>
              <Card.Title>
                <h3 className="text-center mb-4">Urejanje sponzorja</h3>{" "}
              </Card.Title>

              <Form onSubmit={updateSponsor}>
                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerName">Ime sponzorja:</Form.Label>
                  <i className="bi bi-newspaper m-1"></i>
                  <Form.Control
                    type="text"
                    id="playerName"
                    placeholder="Vnesite ime sponzorja"
                    aria-describedby="newsTitle"
                    defaultValue={sponsorName}
                    onChange={(e) => setSponsorName(e.target.value)}
                    name="name"
                    required
                  />
                </FormGroup>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="imageInput">
                    Nalaganje logotipa sonzorja:
                  </Form.Label>
                  <i className="bi bi-images m-1"></i>

                  <Form.Control
                    id="imageInput"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleFileUpload(e.target.files)}
                  />
                </Form.Group>

                <FormGroup>
                  <div className="row">
                    <div className="col d-flex align-items-center justify-content-center">
                      <Button type="submit" variant="success">
                        <span className="btn-label">
                          <i className="bi bi-plus-circle m-1"></i>
                          Posodobi sponzorja
                        </span>
                      </Button>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                      <Link
                        className="btn btn-danger"
                        role="button"
                        to="/sponzorji"
                      >
                        <span className="btn-label">
                          <i className="bi bi-x-octagon m-1"></i>
                          Prekliči
                        </span>
                      </Link>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </Card.Body>

            {sponsorImage === "" ? (
              <Card.Text style={{ margin: "10px" }}>
                Ni prehodne slike sponzorja!
              </Card.Text>
            ) : (
              <ListGroup variant="flush" style={{ margin: "10px" }}>
                <ListGroup.Item key={Math.random()}>
                  <Card>
                    <Card.Img
                      style={{
                        maxWidth: "400px",
                        maxHeight: "600px",
                        margin: "10px",
                      }}
                      className="img-fluid rounded mx-auto d-block"
                      src={sponsorImage}
                      alt="Slika"
                    />

                    <Card.Footer className="text-center">
                      <Button
                        variant="danger"
                        onClick={(e) => handleImageDeletion()}
                      >
                        Izbriši sliko
                      </Button>
                    </Card.Footer>
                  </Card>
                </ListGroup.Item>
              </ListGroup>
            )}
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default EditSponsor;
