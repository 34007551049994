import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useCommonContext from '../hooks/useCommonContext'

const RequireAuth = () =>
{
    const { auth } = useCommonContext()

    return (
        auth?.accessToken
            ?
            <Outlet /> :
            <Navigate to="/login" replace />
    )
}

export default RequireAuth
