import React, { useState, createContext } from 'react'

const CommonContext = createContext({})

export const CommonProvider = ({ children }) =>
{
    const [auth, setAuth] = useState({})
   
    return (
        <CommonContext.Provider value={{
            "auth": auth,
            "setAuth": setAuth,

        }} >
            {children}
        </CommonContext.Provider>
    )
}

export default CommonContext;