import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  FormGroup,
  ListGroup,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import convertToBase64 from "../../utils/convertImgToBase64";
import BASE_URL from "../../utils/baseUrl";

const EditPlayer = () => {
  const history = useNavigate();
  const api = useFetch();

  const [playerName, setPlayerName] = useState("");
  const [playerSurname, setPlayerSurname] = useState("");

  const [playerPositions, setPlayerPositions] = useState([]);
  const [selectedPlayerPositionId, setSelectedPlayerPositionId] = useState("");

  const [playerImage, setPlayerImage] = useState("");
  const routeParams = useParams();

  const fetchPlayerPositions = async (signal) => {
    try {
      const response = await fetch(`${BASE_URL}/positions/`, {
        method: "GET",
        signal: signal,
        headers: { "Content-Type": "application/json" },
      });
      const positions = await response.json();
      setPlayerPositions(positions);
    } catch (error) {
      if (error.name !== "AbortError") {
        alert("Napaka pri igralskih pozicij");
      }
    }
  };

  const fetchPlayerById = useCallback(
    async (signal) => {
      try {
        const response = await fetch(
          `${BASE_URL}/player/${routeParams.playerId}`,
          {
            method: "GET",
            signal: signal,
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 404) {
          alert(`Ni igralca z ID-jem ${routeParams.playerId}`);
          return;
        }

        if (response.status === 500) {
          alert(
            `Napaka pri pridobivanju igralca z ID: ${routeParams.playerId}`
          );
          return;
        }

        const playerData = await response.json();
        setPlayerName(playerData.name);
        setPlayerSurname(playerData.surname);

        console.log("nastavitev stanja");
        setSelectedPlayerPositionId(playerData.position);
        setPlayerImage(playerData.image);
      } catch (error) {}
    },
    [routeParams.playerId]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchPlayerById(signal).catch(console.error);
    fetchPlayerPositions(new AbortController().signal).catch(console.error);

    return () => {
      controller.abort();
    };
  }, [fetchPlayerById]);

  const handleFileUpload = async (files) => {
    const file = files[0];
    const base64 = await convertToBase64(file);

    console.log(`base64 ${base64.length}`);
    setPlayerImage(base64);
  };

  const updatePlayer = async (e) => {
    e.preventDefault();
    if (playerName === "" || playerSurname === "") {
      alert("Niste podali imena ali priimka igralca");
      return;
    }

    if (selectedPlayerPositionId === "") {
      setSelectedPlayerPositionId(playerPositions[0]._id);
      console.log("ni izbrane pozicije");
    }

    console.log(`izbrana pozicija: ${selectedPlayerPositionId}`);

    try {
      const { res, data } = await api(
        `/player/${routeParams.playerId}`,
        new AbortController().signal,
        "PUT",
        {
          name: playerName,
          surname: playerSurname,
          image: playerImage,
          position:
            selectedPlayerPositionId === ""
              ? playerPositions[0]._id
              : selectedPlayerPositionId,
        }
      );

      if (res.status === 404) {
        alert("Ni podan id igralca za posodobitev!");
      }

      if (res.status === 200 && data.playerUpdatedSuccessfully === true) {
        console.log("Uspešno posodobili igralca v bazi!");
        history("/pregledIgralcev");
      }
    } catch (error) {
      alert(`Napaka pri posodobitvi igralca! ${error.message}`);
    }
  };

  const handleImageDeletion = async () => {
    setPlayerImage("");

    try {
      const { res, data } = await api(
        `/player/${routeParams.playerId}`,
        new AbortController().signal,
        "PUT",
        {
          image: "",
          deleteImage: true,
        }
      );

      if (res.status === 404) {
        alert("Ni podan id igralca za posodobitev!");
      }

      if (res.status === 200 && data.playerUpdatedSuccessfully === true) {
        console.log("Uspešno posodobili igralca v bazi!");
      }
    } catch (error) {
      alert(`Napaka pri posodobitvi igralca! ${error.message}`);
    }
  };
  return (
    <div>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div className="w-100" style={{ maxWidth: "1000px" }}>
          <Card>
            <Card.Body>
              <Card.Title>
                <h3 className="text-center mb-4">Urejanje igralca</h3>{" "}
              </Card.Title>

              <Form onSubmit={updatePlayer}>
                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerName">Ime igralca:</Form.Label>
                  <i className="bi bi-newspaper m-1"></i>
                  <Form.Control
                    type="text"
                    id="playerName"
                    placeholder="Vnesite ime igralca"
                    aria-describedby="newsTitle"
                    defaultValue={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    name="name"
                    required
                  />
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerSurname">
                    Priimek igralca:
                  </Form.Label>
                  <i className="bi bi-newspaper m-1"></i>
                  <Form.Control
                    type="text"
                    id="playerSurname"
                    placeholder="Vnesite priimek igralca"
                    aria-describedby="newsTitle"
                    defaultValue={playerSurname}
                    onChange={(e) => setPlayerSurname(e.target.value)}
                    name="name"
                    required
                  />
                </FormGroup>
                <FormGroup className="m-2">
                  <Form.Label htmlFor="playerPosition">
                    Pozicija igralca:
                  </Form.Label>
                  <Form.Select
                    aria-label="Pozicija igralca"
                    onChange={(e) => {
                      setSelectedPlayerPositionId(e.target.value);
                    }}
                  >
                    {playerPositions?.map((position) => (
                      <option
                        key={Math.random()}
                        value={position._id}
                        selected={position._id === selectedPlayerPositionId}
                      >
                        {position.positionName}
                      </option>
                    ))}
                  </Form.Select>
                </FormGroup>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="imageInput">
                    Nalaganje slike igralca:
                  </Form.Label>
                  <i className="bi bi-images m-1"></i>

                  <Form.Control
                    id="imageInput"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleFileUpload(e.target.files)}
                  />
                </Form.Group>

                <FormGroup>
                  <div className="row">
                    <div className="col d-flex align-items-center justify-content-center">
                      <Button type="submit" variant="success">
                        <span className="btn-label">
                          <i className="bi bi-plus-circle m-1"></i>
                          Posodobi igralca
                        </span>
                      </Button>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                      <Link
                        className="btn btn-danger"
                        role="button"
                        to="/pregledIgralcev"
                      >
                        <span className="btn-label">
                          <i className="bi bi-x-octagon m-1"></i>
                          Prekliči
                        </span>
                      </Link>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </Card.Body>

            {playerImage === "" ? (
              <Card.Text style={{ margin: "10px" }}>
                Ni prehodne slike igralca!
              </Card.Text>
            ) : (
              <ListGroup variant="flush" style={{ margin: "10px" }}>
                <ListGroup.Item key={Math.random()}>
                  <Card>
                    <Card.Img
                      style={{
                        maxWidth: "400px",
                        maxHeight: "600px",
                        margin: "10px",
                      }}
                      className="img-fluid rounded mx-auto d-block"
                      src={playerImage}
                      alt="Slika"
                    />

                    <Card.Footer className="text-center">
                      <Button
                        variant="danger"
                        onClick={(e) => handleImageDeletion()}
                      >
                        Izbriši sliko
                      </Button>
                    </Card.Footer>
                  </Card>
                </ListGroup.Item>
              </ListGroup>
            )}
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default EditPlayer;
