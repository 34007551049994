import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import {
  Container,
  Card,
  Form,
  Button,
  FormGroup,
  ListGroup,
} from "react-bootstrap";
import BASE_URL from "../utils/baseUrl";
import { Link, useNavigate } from "react-router-dom";

const EditNews = () => {
  const [newsTitle, setNewsTitle] = useState("");
  const [newsText, setNewsText] = useState("");
  const [newsId, setNewsId] = useState("");

  const [newsImages, setNewsImages] = useState([]);
  const [newsNewAddedImages, setNewsNewAddedImages] = useState([]);
  const history = useNavigate();

  const routeParams = useParams();
  const api = useFetch();

  const fetchNews = useCallback(
    async (signal) => {
      try {
        const response = await fetch(
          `${BASE_URL}/news/${routeParams.newsId}`,
          {
            method: "GET",
            signal: signal,
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 404) {
          alert(`Novica z id-jem: ${routeParams.newsId} ne obstaja!`);
          return;
        }

        const data = await response.json();

        setNewsTitle(data.newsTitle);
        setNewsText(data.newsText);

        setNewsImages(data.newsImages);
        setNewsId(data._id);
      } catch (error) {
        if (error.name !== "AbortError") {
          alert("Napaka pri pridovivanju novice za urejanje");
        }
      }
    },
    [routeParams.newsId]
  );

  useEffect(() => {
    const controller = new AbortController();

    fetchNews(controller.signal).catch(console.error);

    return () => {
      controller.abort();
    };
  }, [routeParams.newsId, fetchNews]);

  const updateNews = async (e) => {
    e.preventDefault();
    if (newsText === "" || newsTitle === "") {
      alert("Niste podali naslova in vsebine novice!");
      return;
    }

    console.log(
      `naslov: ${newsTitle} vsebina: ${newsText} slike: ${newsImages.length}`
    );

    try {
      const { res, data } = await api(
        `/news/${routeParams.newsId}`,
        new AbortController().signal,
        "PUT",
        {
          newsTitle: newsTitle,
          newsText: newsText,
          newsImages: newsNewAddedImages,
        }
      );

      if (res.status === 500) {
        alert("Neznana strežniška napaka pri dodajanju novice!");
        return;
      }

      console.log(
        `status: ${res.status} data: ${data.newsUpdatedSuccessfully}`
      );

      if (res.status === 200 && data.newsUpdatedSuccessfully === true) {
        console.log("Posodobili novico v bazi!");
        history("/");
      }
    } catch (error) {
      alert(`Napaka pri dodajaju novice! ${error.message}`);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (files) => {
    for (const file of files) {
      const base64 = await convertToBase64(file);

      console.log(`base64 ${base64.length}`);
      setNewsNewAddedImages((newsImages) => [...newsImages, base64]);
    }
  };

  const handleImageDeletion = async (index) => {
    console.log(`indeks slike za izbris: ${index}`);

    try {
      const { data, res } = await api(
        "/news/deleteImageByIndex",
        new AbortController().signal,
        "POST",
        { imageIndex: index, id: newsId }
      );

      if (res.status === 400) {
        alert(`Napaka pri izbrisu slike! ${data.message}`);
        return;
      }

      if (res.status === 200 && data.imgSuccessfullyDeleted) {
        console.log("Slika uspešno izbrisana!");
        await fetchNews(new AbortController().signal);
      }
    } catch (error) {
      console.log(`napaka pri izrbisu slike: ${error.message}`);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "1000px" }}>
        <Card>
          <Card.Body>
            <Card.Title>
              <h3 className="text-center mb-4">Dodajanje novice</h3>{" "}
            </Card.Title>

            <Form onSubmit={updateNews}>
              <FormGroup className="m-2">
                <Form.Label htmlFor="userNameInput">Naslov novice:</Form.Label>
                <i className="bi bi-newspaper m-1"></i>
                <Form.Control
                  type="text"
                  id="newsTitleInput"
                  placeholder="Vnesite naslov novice"
                  aria-describedby="newsTitle"
                  value={newsTitle}
                  onChange={(e) => setNewsTitle(e.target.value)}
                  name="newsTitle"
                  required
                />
              </FormGroup>

              <FormGroup className="m-2">
                <Form.Label htmlFor="userNameInput">
                  Besedilo novice:
                </Form.Label>
                <i className="bi bi-body-text m-1"></i>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="NK Bukovci so ponovno zmagali..."
                    id="floatingTextarea2"
                    aria-describedby="newsText"
                    value={newsText}
                    onChange={(e) => setNewsText(e.target.value)}
                    style={{ height: "50vh", maxHeight: "150px" }}
                  >
                    required
                  </textarea>
                  <label htmlFor="floatingTextarea2">
                    NK Bukovci so ponovno zmagali...
                  </label>
                </div>
              </FormGroup>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="imageInput">Nalaganje slik:</Form.Label>
                <i className="bi bi-images m-1"></i>

                <Form.Control
                  id="imageInput"
                  type="file"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleFileUpload(e.target.files)}
                />
              </Form.Group>

              <FormGroup>
                <div className="row">
                  <div className="col d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Posodobi novico
                      </span>
                    </Button>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center">
                    <Link className="btn btn-danger" role="button" to="/">
                      <span className="btn-label">
                        <i className="bi bi-x-octagon m-1"></i>
                        Prekliči
                      </span>
                    </Link>
                  </div>
                </div>
              </FormGroup>
            </Form>
          </Card.Body>

          <ListGroup variant="flush" style={{ margin: "10px" }}>
            {newsImages.map((image, index) => (
              <ListGroup.Item key={Math.random()}>
                <Card>
                  <Card.Img
                    style={{
                      maxWidth: "400px",
                      maxHeight: "600px",
                      margin: "10px",
                    }}
                    className="img-fluid rounded mx-auto d-block"
                    src={image}
                    alt="Slika"
                  />

                  <Card.Footer className="text-center">
                    <Button
                      variant="danger"
                      onClick={(e) => handleImageDeletion(index)}
                    >
                      Izbriši sliko
                    </Button>
                  </Card.Footer>
                </Card>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </div>
    </Container>
  );
};

export default EditNews;
