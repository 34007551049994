import { Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useCommonContext from "../../hooks/useCommonContext";

const LogedInNavbar = () => {
  const history = useNavigate();
  const api = useFetch();
  const { auth, setAuth } = useCommonContext();

  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">
            Domača stran
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/addPlayer">
            Dodaj igralca
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/addNews">
            Dodaj novico
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link active"
            aria-current="page"
            to="/addSponsor"
          >
            Dodaj sponzorja
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link active"
            aria-current="page"
            to="/addSlideshowImage"
          >
            Dodaj sliko za projekcijo
          </Link>
        </li>
        
        <li className="nav-item">
          <Link
            className="nav-link active"
            aria-current="page"
            to="/viewSlideshowImage"
          >
            Pregled slik za projekcijo
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link active"
            aria-current="page"
            to="/pregledIgralcev"
          >
            Vsi igralci
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/sponzorji">
            Sponzorji
          </Link>
        </li>

        <li className="nav-item">
          <Button
            className="nav-link active btn btn-outline-light"
            aria-current="page"
            onClick={async () => {
              const logout = async () => {
                const { res, data } = await api(
                  `/users/logout/${auth.userId}`,
                  new AbortController().signal
                );

                if (res.status === 500) {
                  alert("Napaka pri brisanju obnovitvenega žetona!");
                } else if (res.status === 403) {
                  alert("Ni ustreznega žetona za odjavo!");
                } else if (res.status === 200 && data.logout === "ok") {
                  setAuth({});
                } else {
                  alert("Nezanana napaka pri odjavi!");
                }
              };

              await logout();
              history("/");
            }}
          >
            Odjava
          </Button>
        </li>
      </ul>

      <span className="navbar-text">
        <span>Pozdravljen uporabnik {auth.username}!</span>
      </span>
    </div>
  );
};

export default LogedInNavbar;
